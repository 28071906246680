<template>
    <div id="home">
        <header v-if="game">
            <template v-if="isBrawlStars">
                <h1 id="title">{{ game.title }}</h1>
            </template>
            <template v-else>
                <div id="title-curved">
                    <svg viewBox="0 0 250 50">
                        <path id="curve" d="M 0 54.812 C 2.827 53.868 46.296 39.845 126.237 40.03 C 204.905 40.216 246.961 53.992 250 55.028" />
                        <text>
                            <textPath xlink:href="#curve" startOffset="50%" text-anchor="middle">
                                {{ game.title }}
                            </textPath>
                        </text>
                    </svg>
                </div>
            </template>
            <img id="logo" alt="Game logo" :src="game.logo">
            <p class="caption">{{ game.description }}</p>
        </header>

        <main>
            <Ranking :users="users"/>
            <Prize/>
        </main>

        <footer v-if="game">
            <template v-if="isVideo">
                <video playsinline autoplay muted loop :poster="game.image" id="footer-file">
                    <source :src="game.image" type="video/mp4">
                </video>
            </template>
            <template v-else>
                <img :src="game.image" id="footer-file">
            </template>
        </footer>
    </div>
</template>

<script lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

import { User } from '@/interfaces/User'
import { Game } from '@/interfaces/Game'

import Ranking from '@/modules/scores/Ranking.vue'
import Prize from '@/modules/prize/Prize.vue'

export default {
    name: 'Home',
    components: {
        Ranking,
        Prize,
    },
    setup() {
        const store = useStore()

        const users = computed(() => store.getters.getUsers as User[])

        const games = computed(() => {
            return store.getters.getGames
        })

        const gameId = computed(() => {
            return store.getters.getSelectedGameId
        })

        const gameName = ref('brawl-stars')

        const game = computed(() => {
            const selectedGameId = gameId.value
            const allGames = games.value

            return allGames.find((game: Game) => game.id == selectedGameId)
        })

        const styleMap = computed(() => store.getters.getStyleMap)

        const isBrawlStars = computed(() =>{
            if( game.value && game.value.name == 'Brawl Stars' ) return true
            else return false
        })

        const isVideo = computed(() =>{
            let fileUrl = ''
            const regexVideo = /\.(mp4|mov|wmv|avi|webm)$/i

            if( game.value ) {
                fileUrl = game.value.image
            }

            return regexVideo.test(fileUrl)
        })

        const importStyles = async() => {
            cleanStyles()

            switch (gameId.value) {
                case 1:
                    gameName.value = 'brawl-stars'
                    break
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                    gameName.value = 'fortnite'
                    break
                default:
                    gameName.value = 'brawl-stars'
            }
            
            await import(`@/styles/themes/${ gameName.value }/styles.scss`)
            
            const linkStylesheetElements = document.head.querySelectorAll(`[rel="stylesheet"]`)
            const currentStyleMap = styleMap.value

            if (!currentStyleMap.has(gameName.value)) {
                store.commit('SET_STYLEMAP', { gameName, linkStylesheetElements })
            } else {
                if (linkStylesheetElements.length == 0) {
                    currentStyleMap.get(gameName.value).forEach((element:any) => document.head.append(element))
                }
            }
        }

        const cleanStyles = () => {
            const linkStylesheetElements = document.head.querySelectorAll(`[rel="stylesheet"]`)

            linkStylesheetElements.forEach(linkStylesheet => {
                linkStylesheet.remove()
            })
        }

        onMounted(() => {
            const fullDate = new Date()
            const date = fullDate.getDate()
            const hour = fullDate.getHours()
            const min = fullDate.getMinutes()

            store.commit('SET_SELECTEDGAMEID', 1)

            if ( date === 15 && hour === 18 ) {
                store.commit('SET_SELECTEDGAMEID', 2)
            }

            if ( date === 23 && hour === 18 ) {
                store.commit('SET_SELECTEDGAMEID', 3)
            }

            if (date === 24 
            && ((hour === 16 && min >= 30) 
            || (hour === 17 && min <= 30))) {
                store.commit('SET_SELECTEDGAMEID', 4)
            }

            if ( date === 25 && hour === 18 ) {
                store.commit('SET_SELECTEDGAMEID', 5)
            }

            if ( date === 28 && hour === 17 ) {
                store.commit('SET_SELECTEDGAMEID', 6)
            }

            if ( date === 29 && hour === 18 ) {
                store.commit('SET_SELECTEDGAMEID', 7)
            }

            store.dispatch('fetchGames')
            store.dispatch('fetchUsers')

            importStyles()
        })

        return {
            users,
            games,
            game,
            isBrawlStars,
            isVideo,
        }
    }
}
</script>
