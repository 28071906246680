<template>
    <label class="label">{{ title }}</label>
    <div class="control selector-container">
        <select name="option" :id="id" @change="sendGameId">
            <option selected>{{ defaultSelectedText }}</option>
            <option
                v-for="item in options"
                :key="item.name"
                :value="item.id"
                >
                {{ item.name }}
            </option>
        </select>
        <i class="fa fa-angle-down set"></i>
    </div>
</template>

<script lang="ts">
import { ref, watch } from 'vue'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import { Game } from '@/interfaces/Game'

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        defaultSelectedText: {
            type: String,
            required: true,
        },
        options: {
            type: Array as () => Game[],
            required: true,
        }
    },
    setup() {
        const store = useStore()

        const gameId = ref(1)
        const selectedGameId = computed(() => store.getters.getSelectedGameId as number)

        const gameName = ref('brawl-stars')
        const selectedGameName = computed(() => store.getters.getSelectedGameName as string)

        const styleMap = computed(() => store.getters.getStyleMap)

        const sendGameId = ( event: Event ) => {
            if( event.target ) {
                gameId.value = parseInt((event.target as HTMLInputElement).value) 

                switch (gameId.value) {
                    case 1:
                        gameName.value = 'brawl-stars'
                        break
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                        gameName.value = 'fortnite'
                        break
                    default:
                        gameName.value = 'brawl-stars'
                }

                store.commit('SET_SELECTEDGAMEID', gameId.value)
                store.commit('SET_SELECTEDGAMENAME', gameName.value)
            }
        }

        const importStyles = async() => {
            cleanStyles()
            
            await import(`@/styles/themes/${ gameName.value }/styles.scss`)
            
            const linkStylesheetElements = document.head.querySelectorAll(`[rel="stylesheet"]`)
            const currentStyleMap = styleMap.value

            if (!currentStyleMap.has(gameName.value)) {
                store.commit('SET_STYLEMAP', { gameName, linkStylesheetElements })
            } else {
                if (linkStylesheetElements.length == 0) {
                    currentStyleMap.get(gameName.value).forEach((element:any) => document.head.append(element))
                }
            }
        }

        const cleanStyles = () => {
            const linkStylesheetElements = document.head.querySelectorAll(`[rel="stylesheet"]`)

            linkStylesheetElements.forEach(linkStylesheet => {
                linkStylesheet.remove()
            })
        }

        onMounted(() => {
            importStyles()
        })

        watch(selectedGameId, () => {
            importStyles()
        })

        return {
            sendGameId,
        }
    },
}
</script>