<template>
    <div class="subtitle-container"><h2 class="subtitle striped-line-right">{{ prize.title }}</h2></div>
    <div id="prize-container" class="path two external light-translucent">
        <div class="path two inner med-dark-translucent">
            <div id="prize">
                <img class="prize-deco" alt="Deco left" src='/assets/deco-left.png'>
                <img id="prize-img" :alt="prize.name" :src='prize.imageUrl'>
                <p id="prize-txt">{{ prize.description }}</p>
                <img class="prize-deco" alt="Deco right" src='/assets/deco-right.png'>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    setup() {
        const prize = {
            title: 'PRIZE',
            name: 'Samsung S24',
            description: 'Gana un Galaxy S24',
            imageUrl: require('../../assets/samsung-s24.png')
        }

        return {
            prize,
        }
    },
}
</script>
