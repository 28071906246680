<template>
    <div id="login">
        <header>
            <h1 id="title">{{ title }}</h1>
        </header>
        <main>
            <div class="card">
                <div class="card-content form">
                    <div class="field">
                        <ItemSelector 
                        :title="selectorTitle" 
                        :id="selectorId" 
                        :defaultSelectedText="defaultSelectedText" 
                        :options="games"
                        />
                    </div>
                    <router-link :to="'/'">
                        <button class="submit">Top ranking</button>
                    </router-link>
                    <router-link :to="'admin'">
                        <button class="submit">Admin</button>
                    </router-link>
                </div>
            </div>
        </main>
    </div>
</template>

<script lang="ts">
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import ItemSelector from '@/modules/login/ItemSelector.vue'

import { Game } from '@/interfaces/Game'

export default {
    name: 'Login',
    components: {
        ItemSelector,
    },
    setup() {
        const store = useStore()

        const title = 'Log in'

        const selectorTitle = 'Choose a game'
        const selectorId = 'game-selector'
        const defaultSelectedText = 'Select'

        const games = computed(() => {
            return store.getters.getGames as Game[]
        })

        onMounted(() => {
            store.dispatch('fetchGames')
        })

        return {
            title,
            selectorTitle,
            selectorId,
            defaultSelectedText,
            games,
        }
    }
}
</script>