import axios from 'axios'

import { User } from '@/interfaces/User'
import { Game } from '@/interfaces/Game'

const baseURL = process.env.API_URL

const axiosInstance = axios.create({
    baseURL,
})

export async function getGames(): Promise<Game[]> {
    try {
        const response = await axiosInstance.get(`api/fullstack/games`)
        return response.data
    } catch ( error ) {
        console.error('Error fetching games:', error)
        return []
    }
}

export async function getUsers( gameId: any ): Promise<User[]> {
    try {
        const response = await axiosInstance.get(`api/fullstack/users/list/${ gameId }`)
        return response.data
    } catch ( error ) {
        console.error('Error fetching users:', error)
        return []
    }
}

export async function submit(gameId: any, form: any): Promise<User | undefined> {
    try {
        const response: User = await axiosInstance.post(`api/fullstack/users/${gameId}`, form)
        return response
    } catch ( error ) {
        console.error('Error creating user:', error)
        return
    }
}

export async function update(gameId: any, userId: any, form: any): Promise<User | undefined> {
    try {
        const response: User = await axiosInstance.put(`api/fullstack/users/${userId}/${gameId}`, form)
        return response
    } catch ( error ) {
        console.error('Error creating user:', error)
        return
    }
}

export async function updateUser( userId:any, gameId: any ): Promise<User[]> {
    try {
        const response = await axiosInstance.get(`api/fullstack/users/list/${ gameId }`)
        return response.data
    } catch ( error ) {
        console.error('Error fetching users:', error)
        return []
    }
}

export async function deleteUser( userId: any ): Promise<User[]> {
    try {
        const response = await axiosInstance.delete(`api/fullstack/users/${ userId }`)
        return response.data
    } catch ( error ) {
        console.error('Error deleting user:', error)
        return []
    }
}

export async function fetchImage( imageId: string ): Promise<string> {
    try {
        const response = await axiosInstance.get(`/images/${ imageId }`)
        return response.data.url
    } catch (error) {
        console.error('Error fetching image:', error)
        throw error
    }
}

export async function fetchVideo( videoId: string ): Promise<string> {
    try {
        const response = await axiosInstance.get(`/videos/${ videoId }`)
        return response.data.url
    } catch (error) {
        console.error('Error fetching video:', error)
        throw error
    }
}
