import { createStore } from 'vuex'

import { getGames, getUsers } from '@/api/api'

import { Game } from '@/interfaces/Game'
import { User } from '@/interfaces/User'

export default createStore({
  state: {
    games: [],
    users: [],
    selectedGameId: 1,
    selectedGameName: 'brawl-stars',
    styleMap: new Map(),
  },
  getters: {
    getGames: (state) => state.games,
    getUsers: (state) => state.users,
    getSelectedGameId: (state) => state.selectedGameId,
    getSelectedGameName: (state) => state.selectedGameName,
    getStyleMap: (state) => state.styleMap,
  },
  mutations: {
    SET_GAMES(state, games) {
      state.games = games
    },
    SET_USERS(state, users) {
      state.users = users
    },
    SET_SELECTEDGAMEID(state, payload) {
      state.selectedGameId = payload
    },
    SET_SELECTEDGAMENAME(state, payload) {
      state.selectedGameName = payload
    },
    SET_STYLEMAP(state, { gameName, linkStylesheetElements }) {
      const name = gameName.value

      if (!state.styleMap.has(name)) {
        state.styleMap.set(name, linkStylesheetElements)
      } else {
        console.error(`La clave ${ name } ya existe en el mapa de estilos:`)
      }
    },
  },
  actions: {
    async fetchGames({ commit }) {
      try {
        const data: Game[] = await getGames()
        commit("SET_GAMES", data)
      } catch (error) {
        alert(error)
        console.error(error)
      }
    },

    async fetchUsers({ commit }) {
      try {
        const data: User[] = await getUsers( this.state.selectedGameId )
        commit("SET_USERS", data)
      } catch (error) {
        alert(error)
        console.error(error)
      }
    },
  },
})
