<template>
    <div class="subtitle-container"><h2 class="subtitle slashed-line-both">{{ title }}</h2></div>
        <div id="ranking-container" class="path three external light">
            <div class="path three inner med-dark">
                <div class="top-deco">
                    <div>· · · · ·</div>
                    <div>· · · · ·</div>
                </div>
                <h3 class="score-title">SCORE</h3>
                <div v-for="(ranked, index) in topThreeUsers" :key="index" class="ranked-container path three-small external light">
                    <div class="ranked-content path three-small inner dark">
                        <div class="position w-1/6">
                            <span>{{ index + 1 }}º</span>
                        </div>
                        <div class="nickname w-2/6">
                            <span>{{ ranked.nickname }}</span>
                        </div>
                        <div class="score-num line-trapezoid w-2/6">
                            <span>{{ ranked.score }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script lang="ts">
import { computed, onMounted, ref } from 'vue'

import { User } from '@/interfaces/User'

export default {
    props: {
        users: {
            type: Array as () => User[],
            required: true,
        }
    },
    setup( props: any ) {
        const title = 'RANKING'

        const orderedUsers = computed(() => {
            return props.users.slice().sort((a: any, b: any) => {
                return b.score - a.score
            })
        })

        const topThreeUsers = computed(() => {
            return orderedUsers.value.slice(0, 3);
        })

        return {
            title,
            topThreeUsers,
        }
    },
}
</script>
